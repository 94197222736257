
:root {
  --green: #0ea47a;
  --light-green: #12d39d;
  --dark-green: #0a7557;
  --white: #ffffff;
  --gray: #e6e6e6;
  --black: #111111;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.featured-stack::-webkit-scrollbar {
  height: 5px;
}

.featured-stack::-webkit-scrollbar-button {
  display: none;
}

/* Add custom scrollbar track styling */
.featured-stack::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 2px;
}

/* Add custom scrollbar thumb (the draggable part) styling */
.featured-stack::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  width: 2px;
}